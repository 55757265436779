// App.jsx
import React, { useState, useEffect } from 'react';
import CinemaMap from './components/Map';
import { Loading, Error } from './components/Loading';
import { useCinemas } from './hooks/useCinemas';
import { useMovies } from './hooks/useMovies';
import { Drawer } from 'vaul';

const App = () => {
    const { cinemas, loading, error } = useCinemas();
    const { movies, loadingMovie, errorMovie } = useMovies();
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(true);
    const snapPoints = ['148px', '355px', 1];
    const [snap, setSnap] = useState(snapPoints[1]);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (loading || loadingMovie) return <Loading message="Chargement de la carte..." />;
    if (error || errorMovie) return <Error message={error || errorMovie} />;

    const validCinemas = cinemas.filter(cinema =>
        cinema.coordinates.lat != null && cinema.coordinates.lon != null
    );

    return (
        <div style={{
            position: 'relative',
            height: '100vh',
            width: '100%'
        }}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}>
                <CinemaMap cinemas={validCinemas} />
            </div>

            {isMobile && (
                <Drawer.Root modal={false} dismissible={false} open={open} onOpenChange={setOpen} snapPoints={snapPoints} activeSnapPoint={snap} setActiveSnapPoint={setSnap} /*fadeFromIndex={1}*/>
                    <Drawer.Portal>
                        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
                        <Drawer.Content className="bg-gray-100 h-fit fixed bottom-0 left-0 right-0 outline-none"  style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: '90vh',
                            backgroundColor: 'white',
                            boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)',
                            zIndex: 9999,
                            pointerEvents: 'auto'
                        }}>
                            <div style={{
                                height: '35px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'grab'
                            }}>
                                <div style={{
                                    width: '48px',
                                    height: '6px',
                                    backgroundColor: '#F6F7F5',
                                    borderRadius: '9999px'
                                }} />
                            </div>

                            <div className="px-3">
                                <h2 className="text-lg font-bold text-gray-500 mb-3">
                                    Les séances de la journée :
                                </h2>

                                <div className="grid grid-cols-3 gap-2">
                                    {movies.slice(0, 6).map(movie => (
                                        <div
                                            key={movie.id}
                                            className="relative bg-white overflow-hidden shadow-lg rounded-xl"
                                        >
                                            {/* Movie Poster */}
                                            <img
                                                src={movie.poster}
                                                alt={movie.title}
                                                className="w-full h-40 object-cover rounded-xl"
                                            />

                                            {/* Faded Overlay */}
                                            <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black via-black/60 to-transparent p-2 flex items-end">
                                                <h5 className="text-xs font-semibold text-white text-left w-full leading-tight line-clamp-2">{movie.title}</h5>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </Drawer.Content>
                    </Drawer.Portal>
                </Drawer.Root>

            )}
        </div>
    );
};

export default App;