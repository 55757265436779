export const formatCinemaData = (cinema) => ({
    id: cinema.id,
    name: cinema.name,
    address: cinema.address,
    url: cinema.url,
    coordinates: cinema.coordinates || {
        lat: null,
        lon: null
    }
});
export const formatMovieData = (movie) => ({
    id: movie.i,
    title: movie.t,
    poster: movie.p
});
