import { useState, useEffect } from 'react';
import { formatMovieData } from '../utils/mapUtils';

export const useMovies = () => {
    const [movies, setMovies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMovies = async () => {
            try {
                setLoading(true);
                const versionParam = new Date().toISOString().split('T')[0].replace(/-/g, '');
                const response = await fetch(`/data/movies.json?v=${versionParam}`);

                if (!response.ok) {
                    throw new Error('Failed to load movies');
                }

                const data = await response.json();
                // Format movie data (ensure compatibility)
                const formattedMovies = data.movies.map(formatMovieData);

                setMovies(formattedMovies);
                setError(null);
            } catch (error) {
                console.error('Error fetching movie data:', error);
                setError('Erreur lors du chargement des films populaires');
            } finally {
                setLoading(false);
            }
        };

        fetchMovies();
    }, []);

    return { movies, loading, error };
};
