import React, { useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const CinemaMap = ({ cinemas }) => {
    const mapRef = useRef(null);

    // Coordinates for Paris
    const parisLatitude = 48.8566;
    const parisLongitude = 2.3522;

    // Define the bounds for Paris
    const parisBounds = [
        [48.815573, 2.224199], // Southwest corner
        [48.902145, 2.46992]   // Northeast corner
    ];

    return (
        <MapContainer
            center={[parisLatitude, parisLongitude]}
            zoom={13}
            ref={mapRef}
            style={{ height: "100vh", width: "100vw" }}
            maxBounds={parisBounds}
            maxBoundsViscosity={1.0}
            minZoom={10}
            maxZoom={18}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="     https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=f5064648b2524e31bbbc1f39a0e9e4b9 "
            />
            {cinemas.map((cinema) => (
                <Marker
                    key={cinema.id}
                    position={[cinema.coordinates.lat, cinema.coordinates.lon]}
                >
                    <Popup>
                        <div>
                            <h3>{cinema.name}</h3>
                            <p>{cinema.address}</p>
                        </div>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default CinemaMap;
